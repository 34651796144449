module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/buildhome/repo/src/components/layout.js"},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uxo","short_name":"Uxo","start_url":"/","background_color":"#b4fdf4","theme_color":"#302167","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/buildhome/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
