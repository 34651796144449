import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <div className="flex">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="lg:ml-20 mt-12 md:mt-0 w-full font-body relative">
        <main>{children}</main>
        <Footer />
      </div>
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
