import React from "react"
import Fade from 'react-reveal/Fade'
import { FaTwitter, FaBehance, FaFacebookF } from 'react-icons/fa'

export default props => 
<Fade right cascade>
<div className={props.contClass}>

<a
  href="https://twitter.com/withuxo"
  target="_blank"
  rel="noopener noreferrer"
  className={props.btnClass}
>
  <FaTwitter/>
</a>
<a
  href="https://www.facebook.com/withuxo/"
  target="_blank"
  rel="noopener noreferrer"
  className={props.btnClass}
>
  <FaFacebookF />
</a>
<a
  href="https://www.behance.net/withuxo7997"
  target="_blank"
  rel="noopener noreferrer"
  className={props.btnClass}
>
  <FaBehance />
</a>

</div></Fade>