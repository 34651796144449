import { Link } from "gatsby"
import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import SocialLinks from "../components/social-links"

import logo from "../images/logo.svg"

const Footer = () => (
  <footer className="bg-dark-violet w-full jaune md:min-h-16 flex text-jaune align-middle justify-center content-center">
  <div className="self-center pl-5 py-4 flex flex-col lg:flex-row">
    <a
    href="mailto:talk@with-uxo.com"
    className="mr-2 hover:text-rose">Contact</a>
    <AniLink to="/mentions-legales"
    className="mr-2 hover:text-rose">Mentions légales</AniLink>
    <span>© {new Date().getFullYear()}</span>
  </div>
  
  <SocialLinks
    contClass="flex m-auto lg:mr-3"
    btnClass="hover:bg-rose bg-jaune text-violet self-center rounded-full h-8 w-8 flex items-center justify-center mx-1"
  />
</footer>
)


export default Footer
