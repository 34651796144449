import PropTypes from "prop-types"
import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import logo from "../images/logo.svg"

const Header = ({ siteTitle }) => (
  <TransitionPortal>
  <header className="top-0 fixed lg:w-20 lg:inset-y-0 left-0 h-12 lg:h-full w-screen bg-bleu flex z-20 justify-center">
    <AniLink cover
    direction="right"
    to="/"
    alt={siteTitle}
    className="self-center"
      bg="#b4fdf4">
      <img className="object-contain h-8 lg:h-24 lg:-rotate-90" src={logo} />
    </AniLink>
  </header>
  </TransitionPortal>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
