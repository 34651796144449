// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/buildhome/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-competence-js": () => import("/opt/buildhome/repo/src/templates/competence.js" /* webpackChunkName: "component---src-templates-competence-js" */),
  "component---src-pages-404-js": () => import("/opt/buildhome/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-uxo-js": () => import("/opt/buildhome/repo/src/pages/a-propos-uxo.js" /* webpackChunkName: "component---src-pages-a-propos-uxo-js" */),
  "component---src-pages-contact-js": () => import("/opt/buildhome/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/buildhome/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/buildhome/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

